import { createRouter, createWebHistory } from 'vue-router'

import App from './App.vue'

import WxCallback from '@/mobile/pages/WxCallback.vue'
import Auth from '@/mobile/pages/Auth.vue'
import Shelf from '@/pages/Shelf.vue'
import TagManagement from '@/mobile/pages/TagManagement.vue'
import Mine from '@/pages/Mine.vue'
import Reward from '@/mobile/pages/Reward/Reward.vue'
import Atlas from '@/pages/Atlas/Atlas.vue'
import UnitLearn from '@/pages/UnitLearn/UnitLearn.vue'
import PublicPackage from '@/pages/PublicPackage.vue'
import SearchPanel from '@/mobile/pages/SearchPanel.vue'
import Landing from '@/mobile/pages/Landing.vue'
import IdentityOnboarding from '@/components/IdentityOnboarding/IdentityOnboarding.vue'
import PackageBrowserPage from '@/mobile/pages/pkg/PackageBrowserPage.vue'

import type { RouteRecordRaw } from 'vue-router'
import UserSetting from './pages/UserSetting.vue'
import Message from '@/pages/Message.vue'
import NotFound from '@/pages/NotFound.vue'
import { cancelReport } from '@/pages/UnitLearn/report'
import { debugRoute, setupHistoryMgmt } from '@/shared'
import BetaFeature from '@/pages/BetaFeature/BetaFeature.vue'

export const WX_CALLBACK_PATH = '/wxcallback'

// meta:
//  - auth: boolean
const routes: RouteRecordRaw[] = [
  {
    path: WX_CALLBACK_PATH,
    component: WxCallback,
  },

  {
    path: '/beta-feature',
    name: 'beta-feature',
    component: BetaFeature,
    meta: {
      needAuth: true,
    },
  },

  {
    path: '/auth',
    component: Auth,
    name: 'auth',
  },

  {
    path: '/landing',
    component: Landing,
    name: 'landing',
  },

  {
    path: '/identity-onboarding',
    component: IdentityOnboarding,
    name: 'identity-onboarding',
  },

  {
    path: '/pkgs/:hashId',
    component: PublicPackage,
    name: 'publicPackage',
  },
  {
    path: '/tag/management',
    component: TagManagement,
    name: 'tag/management',
  },
  {
    path: '/404',
    component: NotFound,
    name: '404',
  },
  {
    path: '/',
    component: App,
    children: [
      { path: '', redirect: 'atlas' },
      {
        path: 'atlas',
        component: Atlas,
        name: 'atlas',
        meta: {
          keepAlive: true,
        },
      },
      {
        path: 'shelf',
        component: Shelf,
        name: 'shelf',
      },
      {
        path: 'mine',
        component: Mine,
        name: 'mine',
      },
      {
        path: 'reward',
        component: Reward,
        name: 'reward',
      },
      {
        path: '/unit/learn',
        component: UnitLearn,
        name: 'unit/learn',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/packages/:id',
        component: PackageBrowserPage,
        name: 'package',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/message',
        component: Message,
        name: 'message',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/user-setting',
        component: UserSetting,
        name: 'user-setting',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/searchPanel',
        component: SearchPanel,
        name: 'searchPanel',
        meta: {
          fullscreen: true,
        },
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
]
if (!_global.isProd) {
  routes.push(debugRoute)
}

const router = createRouter({
  history: createWebHistory(),
  routes,
})

setupHistoryMgmt(router)

router.beforeEach((to, _from) => {
  const needAuth = to.matched[0].path === '/' || to.meta.needAuth

  if (needAuth && !_store.isLoggedIn) {
    return {
      name: 'landing',
      query: {
        redirect: encodeURIComponent(to.fullPath),
      },
      replace: _global.isInsideWechat,
    }
  }
})

router.afterEach(async to => {
  if (['landing', 'unit/learn', 'unit/quiz'].includes(to.name as string)) return

  if (_store.checkStageUnit()) {
    const ok = await _confirm({
      type: 'warn',
      content: '上次学习中断了 是否要恢复进度、继续学习?',
      cancelText: '恢复进度、继续学习',
      okText: '放弃进度、不学了',
    })

    if (ok === false) {
      router.push({
        name: 'unit/learn',
      })
      return
    }

    if (ok) {
      await cancelReport()
      _store.clearStageUnit()
    }
  }
})

export default router
