<template>
  <RatioSpacedContainer
    class="h-[var(--ld-viewport-height)] overflow-auto"
    :top="4"
    :bottom="6"
  >
    <div class="w-full">
      <AuthForm
        class="px-4 mx-auto py-10"
        @done="onAuthDone"
      />

      <div class="mt-6 text-center">
        <span>{{ _t('没有账号? ') }}</span>
        <TextButton
          class="register-btn"
          @click="onWxRegister"
        >
          {{ _t('去微信注册') }}
        </TextButton>
      </div>
    </div>
  </RatioSpacedContainer>
</template>

<script lang="ts" setup>
import AuthForm from '@/components/BetaTestAuthForm/BetaTestAuthForm.vue'
import Button from '@/components/Button.vue'
import { openWechatApp } from '@/utils/wechat'
import { useClipboard } from '@vueuse/core'
import { omit } from 'lodash-es'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const { copy } = useClipboard({ source: '' })

async function onAuthDone() {
  if (route.query.from) {
    const from = decodeURIComponent(route.query.from as string)

    router.replace({
      path: from,
      query: {
        ...omit(route.query, ['from']),
      },
    })
  } else {
    router.replace({
      name: 'shelf',
    })
  }
}

function onWxRegister() {
  _confirm({
    type: 'wechat',
    icon: {
      name: 'ld-avatar',
      type: 'svg',
    },
    okText: _t('复制名称、去微信'),
    content: _t('目前仅支持微信注册\n请前往微信搜索：小灵鸭公众号'),
    async onConfirm() {
      copy('小灵鸭公众号')
      openWechatApp()

      return false
    },
  })
}
</script>

<style scoped></style>
