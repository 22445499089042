<template>
  <div
    class="flex flex-col h-[var(--ld-viewport-height)] mx-auto bg-ld-background overflow-y-auto"
  >
    <AppBar
      :title="appBarTitle"
      @back="onBack"
    >
      <template
        v-if="card"
        #actions
      >
        <DebugButton
          label="源码"
          @click="showCardInfo = !showCardInfo"
        />
        <Icon
          v-if="isOwner"
          name="card-content-edit"
          class="cursor-pointer w-24px"
          @click="onCardContentEdit"
        ></Icon>
      </template>
    </AppBar>

    <div class="flex flex-col flex-1">
      <Loading
        v-if="data.packageLoading || data.cardsLoading"
        class="h-full"
      />

      <template v-else-if="data.package">
        <TrailHeader
          v-if="data.package.packageAccess == null"
          :package="data.package"
          @unlock="onUnlock"
        />

        <template v-if="card">
          <CardPreview
            v-if="!showCardInfo"
            class="bg-transparent"
            :card="JSON.parse(card.content)"
          />

          <CardSource
            v-else
            :key="card.content"
            :card="card"
          />
        </template>

        <CardList
          v-else
          class="flex-1"
          :package="data.package"
          :chapterId="chapterId"
          :cards="data.cards"
          @onChapterClick="onChapterClick"
          @onCreateCard="onCreateCard"
          @onCardClick="onCardClick"
        ></CardList>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Code } from '@/api/code'
import {
  fetchCards,
  fetchPackageById,
  type Package,
  type CardResponse,
  type ChapterItem,
  PackageAccess,
} from '@/api/package-source'
import Loading from '@/components/Loading.vue'
import AppBar from '@/mobile/components/AppBar.vue'
import { useCommonStore } from '@/stores'
import { computed, onMounted, ref } from 'vue'
import { onUnmounted } from 'vue'
import { reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CardList from '@/components/Package/CardList.vue'
import TrailHeader from '@/components/TrailHeader.vue'
import CardSource from '@/pc/pages/Package/CardSource.vue'
import CardPreview from '@/components/CardPreview/CardPreview.vue'

const store = useCommonStore()
const route = useRoute()
const router = useRouter()
const onBack = () => {
  if (router.canGoBack) {
    router.back()
  } else {
    router.replace({
      name: 'shelf',
    })
  }
}

const packageId = Number(route.params.id)
const chapterId = computed(
  () => (route.query.chapterId as string | undefined) ?? 'root'
)

const cardId = computed(() => route.query.cardId as string | undefined)
const card = computed(() =>
  data.cards.find(card => String(card.id) === cardId.value)
)

const showCardInfo = ref(false)

const isOwner = computed(() => data.package?.owned != null)

function onCardClick(card: CardResponse) {
  if (data.package?.owned == null) {
    return
  }

  // 卡片预览
  router.push({
    query: {
      ...route.query,
      cardId: card.id,
    },
  })
  showCardInfo.value = false
}

function onCardContentEdit() {
  _confirm({
    type: 'notice',
    content: '移动端暂不支持，请使用电脑访问 www.lingduck.top 进行操作',
    okText: '知道了',
  })
}

const appBarTitle = computed(() => {
  if (data.package) {
    if (chapterId.value === 'root') return data.package.name
    return data.package.chapters[chapterId.value].title
  }
  return ''
})

const data = reactive({
  package: undefined as Package | undefined,
  packageLoading: true,
  cards: [] as CardResponse[],
  cardsLoading: true,
})

async function fetchPackage() {
  data.packageLoading = true

  try {
    const res = await fetchPackageById(packageId)

    if (res.code === Code.PackageNotFound) {
      router.replace({
        name: '404',
      })
      return
    }

    data.package = res.data
  } finally {
    data.packageLoading = false
  }
}
const cardListCache = ref<Record<string, CardResponse[]>>({})

async function fetchCardList(chapterId: string) {
  data.cards = []
  if (chapterId == null) return []
  try {
    if (cardListCache.value[chapterId] != null) {
      data.cards = cardListCache.value[chapterId]
    } else {
      data.cardsLoading = true
      data.cards = (await fetchCards(packageId, chapterId)).data.cards
      cardListCache.value[chapterId] = data.cards
      data.cards.forEach(c => {
        store.setCardResponseCache(c.id, c)
      })
    }
  } finally {
    data.cardsLoading = false
  }
}
function onChapterClick(chapter: ChapterItem) {
  router.push({
    query: {
      ...route.query,
      chapterId: chapter.id,
    },
  })
  fetchCardList(String(chapter.id))
}

function onRouteChange() {
  fetchCardList(chapterId.value)
}

function onCreateCard() {
  _confirm({
    type: 'notice',
    content: '移动端暂不支持，请使用电脑访问 www.lingduck.top 进行操作',
    okText: '知道了',
  })
}

function onUnlock(access: PackageAccess) {
  data.package!.packageAccess = access
}

onMounted(() => {
  window.addEventListener('popstate', onRouteChange)
})
onUnmounted(() => {
  window.removeEventListener('popstate', onRouteChange)
})

async function init() {
  data.packageLoading = true
  data.cardsLoading = true
  await fetchPackage()
  if (data.package == null) return
  await fetchCardList(chapterId.value)
}
init()
</script>

<style scoped></style>
