<template>
  <div class="vstack p-4 min-h-full g-safe-area">
    <RatioSpacedContainer class="flex-1">
      <Img
        name="ld-greet"
        class="w-107px"
      />

      <div class="my-4 flex flex-col gap-4 leading-[1.8]">
        <div class="text-33px">{{ _t('像玩游戏一样学习') }}</div>

        <div class="text-24px text-[var(--ld-label-secondary)]">
          {{ _t('笔记变游戏，学习零压力') }}
        </div>
      </div>
    </RatioSpacedContainer>

    <template v-if="_global.isInsideWechat">
      <Button
        class="mt-auto w-full h-56px"
        label="Wechat"
        scene="wechat"
        @click="onWechatLogin"
      >
        <div class="flex justify-center items-center w-full">
          <Icon
            name="wechat-pure"
            class="h-28px"
          />
          <span class="ml-1 text-lg">{{ _t('微信登录') }}</span>
        </div>
      </Button>
    </template>

    <div
      v-else
      class="mb-50px w-full"
    >
      <Button
        class="mt-auto w-full h-56px justify-center"
        :label="_t('马上开始')"
        @click="onLogin"
      ></Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCommonStore } from '@/stores'
import { wechatLogin } from '@/utils/wechat'
import { onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const store = useCommonStore()
const router = useRouter()
const route = useRoute()

onBeforeMount(() => {
  if (store.isLoggedIn) {
    if (route.query.redirect) {
      const redirect = decodeURIComponent(route.query.redirect as string)

      router.replace(redirect)
    } else {
      router.replace({
        name: 'atlas',
      })
    }
  }
})

function onLogin() {
  router.push({
    name: 'auth',
    query: {
      from: route.query.redirect || undefined,
    },
  })
}

function onWechatLogin() {
  wechatLogin()
}
</script>
<style scoped></style>
