<template>
  <DynamicDialog />

  <!--
    entry 中的 relative 是用来给弹窗定位的
    在 pc 上开启移动端模拟时会使用到
  -->
  <div
    id="app-entry"
    ref="entryRef"
    class="h-[var(--ld-viewport-height)] relative"
  >
    <DebugFloatingBall />

    <Loading
      v-if="loading"
      class="h-full"
    />

    <RouterView v-else />
  </div>
</template>

<script setup lang="ts">
import { h, onMounted, onUnmounted, ref, computed } from 'vue'
import DynamicDialog from 'primevue/dynamicdialog'
import { useDialog } from 'primevue/usedialog'
import { setViewportHeight } from '../utils'

import Confirm from '@/components/Confirm.vue'
import InputText from 'primevue/inputtext'
import Button from '@/components/Button.vue'
import { useCommonStore } from '@/stores'
import { getCurrentUser } from '@/api/auth'
import Loading from '@/components/Loading.vue'
import EnergyBuy from '@/components/EnergyBuy.vue'
import DebugFloatingBall from '@/components/DebugFloatingBall.vue'
import Icon from '@/components/Icon.vue'

import type { Component, Ref } from 'vue'
import type { DialogProps } from 'primevue/dialog'
import type { DynamicDialogInstance } from 'primevue/dynamicdialogoptions'
import type {
  OpenDialogPromise,
  ConfirmOptions,
  InputDialogOptions,
} from '../types/global'
import { fetchUserTags } from '@/api/user'
import { useRouter } from 'vue-router'
import bus, { BusEvent } from '@/bus/bus'
import { WX_CALLBACK_PATH } from './router'
import { useWindowSize } from '@vueuse/core'
import { showToast } from 'vant'
import type { Reward } from '@/api/task'
import RewardDialog from '@/components/RewardDialog.vue'
import { DEBUG_ROUTE_PREFIX } from '@/shared'
import db from '@/db'
import { wechatRedirect } from '@/utils/wechat'
import zindexMgmt from '@/utils/zindexMgmt'

const showDebugFloatingBall = computed(
  () => db.debug.showDebugFloatingBall && !_global.isProd
)

const store = useCommonStore()

const windowSize = useWindowSize()

const entrySize = ref({ width: 0, height: 0 })

window._viewSize = entrySize

const entryRef = ref()
const enableMobileSimulator = windowSize.width.value > 1024

function onWindowResize() {
  entrySize.value.width = entryRef.value?.clientWidth ?? 0
  entrySize.value.height = entryRef.value?.clientHeight ?? 0
}

onMounted(() => {
  window.addEventListener('resize', onWindowResize)

  if (enableMobileSimulator) {
    const W = 450
    const RATIO = 1.78
    document.documentElement.style.setProperty(
      '--ld-viewport-height',
      W * RATIO + 'px'
    )
    entryRef.value.classList.add('mx-auto', 'mt-56px', 'w-450px', 'shadow-lg')
    document.body.classList.add('bg-zinc-50')
  } else {
    // 100vh 在 ios chrome 和 safari 并不是是实际视口高度，包含了底部工具栏。
    // 所以这里需要获取到实际视口高度然后设置为 css 变量来使用
    setViewportHeight()
    window.addEventListener('resize', setViewportHeight)
  }
  onWindowResize()
})

onUnmounted(() => {
  window.removeEventListener('resize', onWindowResize)
})

// 确保获取到微信 openId，微信支付需要用
function ensureWechatOpenId() {
  if (location.pathname !== WX_CALLBACK_PATH) {
    if (_global.isInsideWechat && !_db.wxOpenId) {
      // redirect to get wechat code
      wechatRedirect()
      return
    }
  }
}

const loading = store.token !== '' ? ref(true) : ref(false)
if (!location.pathname.startsWith(DEBUG_ROUTE_PREFIX)) {
  if (loading.value) {
    onMounted(() => {
      // 自动登录
      getCurrentUser(true)
        .then(res => {
          if (res.user) {
            store.login(store.token, res.user)
          }
          ensureWechatOpenId()
        })
        .finally(() => {
          loading.value = false
        })
    })
  } else {
    // 未登录时，直接跳转微信获取 code & openId
    ensureWechatOpenId()
  }
} else {
  loading.value = false
}

const messageFn = () => {
  return (content: string, title?: string) => {
    showToast({
      message: title ? `${title}\n ${content}` : content,
      duration: 3000,
      className: 'toast-mobile-content',
      zIndex: zindexMgmt.nextZIndex(),
    })
  }
}

window._message = {
  error: messageFn(),
  success: messageFn(),
  info: messageFn(),
}
let dialogInstances: DynamicDialogInstance[] = []
const dialog = useDialog()
const router = useRouter()

window._openDialog = function <R>(
  Comp: Component,
  options: {
    title?: string | Ref<string>
    props?: any
    dialog?: DialogProps
    rootClass?: string
    fullscreenInMobile?: boolean
  } = {}
): OpenDialogPromise<R> {
  const { title = '', props = {}, rootClass = '', fullscreenInMobile } = options

  let instance: DynamicDialogInstance

  const titleRef = typeof title === 'string' ? ref(title) : title

  const dialogOptions = options.dialog || ({} as any)
  if (!dialogOptions.pt) {
    dialogOptions.pt = {}
  }
  if (!dialogOptions.pt.root) {
    dialogOptions.pt.root = {}
  }
  if (!dialogOptions.pt.mask) {
    dialogOptions.pt.mask = {}
  }
  dialogOptions.pt.root.class = rootClass

  if (fullscreenInMobile) {
    dialogOptions.pt.root.class += ' g-dialog-fullscreen g-safe-area'
  }

  const maskPt = dialogOptions.pt?.mask ?? {}
  maskPt.style += `;z-index: ${zindexMgmt.nextZIndex()}`

  if (enableMobileSimulator) {
    maskPt.style += `;position: absolute`
  }

  const result = new Promise(resolve => {
    instance = dialog.open(
      () => {
        return h(Comp, {
          ...props,
          onDone: (val: R) => {
            resolve(val)
            instance.close()
          },
        })
      },
      {
        props: {
          modal: true,
          draggable: false,
          ...dialogOptions,
          autoZIndex: false,
          appendTo: enableMobileSimulator
            ? '#app-entry'
            : dialogOptions.appendTo,
          pt: {
            ...dialogOptions.pt,
            mask: maskPt,
          },
        },
        templates: {
          header: () =>
            h(
              'h2',
              {
                class: 'text-xl font-medium',
              },
              titleRef.value
            ),
        },
        onClose() {
          dialogInstances = dialogInstances.filter(i => i !== instance)
          resolve(undefined)
        },
      }
    )
    dialogInstances.push(instance)
  }) as OpenDialogPromise<R>

  result.close = () => instance.close()

  return result
}

window._confirm = function (options): Promise<boolean | undefined> {
  const confirmOptions = (
    typeof options === 'string'
      ? {
          type: 'confirm',
          content: options,
        }
      : options
  ) as ConfirmOptions

  const { onConfirm } = confirmOptions
  const loading = ref(false)

  const closeable =
    confirmOptions.type === 'notice' || confirmOptions.type === 'wechat'
  const dismissableMask = closeable

  let instance: DynamicDialogInstance

  return new Promise(resolve => {
    instance = dialog.open(
      () => {
        return h('div', [
          closeable &&
            h(
              'div',
              {
                class: `h-44px flex items-center`,
              },
              h(Icon, {
                class: 'w-20px ml-auto cursor-pointer',
                name: 'close-circle',
                onClick() {
                  resolve(undefined)
                  instance.close()
                },
              })
            ),
          h(Confirm, {
            ...confirmOptions,
            onConfirm: () => {
              if (!onConfirm) {
                resolve(true)
                instance.close()
                return
              }

              loading.value = true

              onConfirm()
                .then((close: boolean) => {
                  if (close) {
                    resolve(true)
                    instance.close()
                  }
                })
                .finally(() => {
                  loading.value = false
                })
            },

            onCancel: () => {
              resolve(false)
              instance.close()
            },
          }),
        ])
      },
      {
        props: {
          modal: true,
          draggable: false,
          dismissableMask: dismissableMask,
          showHeader: false,
          pt: {
            content: {
              class: `mx-4 ${closeable ? 'mb-4' : 'my-4'} p-0 rounded-none`,
            },
            root: { class: 'g-dialog' },
          },
        },
        onClose() {
          resolve(undefined)
          dialogInstances = dialogInstances.filter(i => i !== instance)
        },
      }
    )
    dialogInstances.push(instance)
  })
}

window._openInputDialog = function (options: InputDialogOptions) {
  let instance: DynamicDialogInstance

  const { onSubmit, placeholder, okText } = options
  const text = ref(options.text ?? '')
  const loading = ref(false)

  return new Promise<string | undefined>(resolve => {
    instance = dialog.open(
      () => {
        return h('div', [
          h(InputText, {
            class: 'w-full mb-4 min-w-[320px]',
            modelValue: text.value,
            placeholder: placeholder,
            'onUpdate:modelValue': (val: string) => {
              text.value = val
            },
          }),
          h(Button, {
            class: 'w-full',
            label: okText,
            onClick: () => {
              loading.value = false

              onSubmit(text.value)
                .then((close: boolean) => {
                  if (close) {
                    resolve(text.value)
                    instance.close()
                  }
                })
                .finally(() => {
                  loading.value = false
                })
            },
          }),
        ])
      },
      {
        props: {
          header: options.title,
          modal: true,
          draggable: false,
        },
        onClose() {
          resolve(undefined)
          dialogInstances = dialogInstances.filter(i => i !== instance)
        },
      }
    )
    dialogInstances.push(instance)
  })
}

window._openBottomSheet = function (comp: Component, options) {
  return _openDialog(comp, {
    props: options?.props,
    rootClass: `w-full m-0 ${options?.rootClass ?? ''}`,
    dialog: {
      showHeader: false,
      position: 'bottom',
      dismissableMask: true,
      pt: {
        root: {
          style: 'max-width: 100%; border-radius: 12px 12px 0px 0px',
        },
        content: {
          style:
            'border-bottom-right-radius:0px; border-bottom-left-radius:0px',
          class: 'p-0 g-safe-area',
        },
      },
    },
  })
}

window._presentContent = function (
  Comp: Component,
  options?: {
    props?: any
    bottomSheetClass?: string
  }
) {
  return _openBottomSheet(Comp, {
    props: options?.props,
    rootClass: options?.bottomSheetClass,
  })
}

window._openEnergyBuy = function () {
  _openBottomSheet(EnergyBuy)
}

window._showRewards = function (rewards: Reward[]) {
  _openDialog(RewardDialog, {
    dialog: {
      showHeader: false,
      pt: {
        content: { class: 'p-4' },
      },
    },
    props: {
      rewards,
    },
  })
}

window._closeAllDialogs = function () {
  dialogInstances.forEach(i => i.close())
  dialogInstances = []
}

window._closeActiveDialog = function () {
  const active = dialogInstances.pop()
  active?.close()
}

const RESET_TIMEOUT = 200
const TOUCH_COUNT = 5

let debugTouchCount = 0
let debugPanelOpen = false
let resetTimer: number
const handleDebugPanelTouch = () => {
  if (debugPanelOpen) return

  clearTimeout(resetTimer)
  debugTouchCount++

  if (debugTouchCount >= TOUCH_COUNT) {
    debugTouchCount = 0
    _openDebugPanel()
  }

  resetTimer = setTimeout(() => {
    debugTouchCount = 0
  }, RESET_TIMEOUT)
}
window._openDebugPanel = function () {
  if (_global.isProd) return

  if (debugPanelOpen) {
    return
  }

  debugPanelOpen = true
  import('@/components/DebugPanel/DebugPanel.vue').then(mod => {
    _openDialog(mod.default, { title: '调试面板' })
      .then(() => {
        debugPanelOpen = false
      })
      .catch(() => {
        location.reload()
      })
  })
}

if (!_global.isProd) {
  window.addEventListener('touchstart', handleDebugPanelTouch)
}

function onLogin() {
  fetchUserTags().then(res => {
    if (res.identityTagResponses.length === 0) {
      router.push({
        name: 'identity-onboarding',
      })
      return
    }
  })
}

router.afterEach(() => {
  _closeAllDialogs()
})

onMounted(() => {
  bus.on(BusEvent.Login, onLogin)
})
onUnmounted(() => {
  bus.on(BusEvent.Login, onLogin)
})
</script>

<style>
.van-toast.toast-mobile-content {
  pointer-events: none;
  padding: 16px;
  font-size: 21px;
  color: white;
  line-height: 1.2;
  background: var(--ld-toastBg);
  border-radius: 8px;
  max-width: 306px;
  min-width: 144px;
  top: 40%;
}
</style>
