import api from './base'

import type { CommonResponse } from './base'

// 使用微信 code 查询 openId
export function queryWechatOpenId(
  code: string,
  wechatType?: 'WEB' | 'APP' | 'MP'
) {
  return api.post<unknown, CommonResponse<{ openId: string }>>(
    `/public/misc/query-wechat-openid`,
    {
      code,
      wechatType,
    }
  )
}
