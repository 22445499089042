<template>
  <div class="h-full flex flex-col bg-ld-background">
    <AppBar
      title="设置"
      @back="onBack"
    />

    <UserSetting class="flex-1 p-4" />
  </div>
</template>
<script setup lang="ts">
import UserSetting from '@/components/UserSetting/UserSetting.vue'
import AppBar from '../components/AppBar.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

function onBack() {
  if (router.canGoBack) {
    router.back()
  } else {
    router.replace({ name: 'mine' })
  }
}
</script>
<style scoped></style>
