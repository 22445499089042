import '../init'

import EntryWrapper from '../EntryWrapper.vue'
import Entry from './Entry.vue'
import db from '../db'

import { loadLocaleMessages } from '../i18n'
import { subscribeStore, useCommonStore } from '../stores/common-store'
import router from './router'

import 'virtual:uno.css'
import './global.css'
import 'animate.css'
import { createMyApp } from '@/app'

import { fastClick } from '@/directives'

window._t = str => str
const app = createMyApp(EntryWrapper, { entry: Entry })

loadLocaleMessages(db.locale).then(init)

function init() {
  const store = useCommonStore()
  subscribeStore(store)

  // global variables
  window._store = store
  window._db = db
  window._app = app
  window._router = router

  app.use(router)
  app.directive('fast-click', fastClick)

  // init app
  app.mount('#app')
}

// 禁止浏览器缩放
document.addEventListener('gesturestart', function (e) {
  e.preventDefault()
})
