<template>
  <div
    class="bg-[var(--surface-hover)] h-[var(--ld-viewport-height)] flex flex-col"
  >
    <SearchPanel
      class="flex-1 overflow-hidden"
      @package-challenge="onPackageChallenge"
      @done="onBack"
    />
  </div>
</template>

<script setup lang="ts">
import SearchPanel from '@/components/SearchPanel/SearchPanel.vue'
import { useRouter } from 'vue-router'

const emit = defineEmits<{
  done: []
}>()

const router = useRouter()

function onBack() {
  if (router.canGoBack) {
    router.back()
  } else {
    router.replace({ name: 'shelf' })
  }
}

function onPackageChallenge(pkgId: number) {
  router.push({
    name: 'atlas',
    query: {
      pkgId,
    },
  })
}
</script>

<style scoped></style>
